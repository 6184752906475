<template lang="pug">
v-dialog(:value="true" max-width="700px" @input="$emit('close')")
  v-card
    v-card-title.d-flex.justify-space-between
      span Привязки аккаунта {{email}}
    v-card-text
      Spinner(v-if="isLoading")
      AccountBindings(v-else :value="itemBindings(id)" @input="handleChangeOrgs")

      v-row(align-end)
        v-spacer
        v-col(cols="12" sm="6")
          v-btn(
            @click="$emit('close')"
            outlined
            block
          ) Закрыть
</template>

<script>
import Spinner from '@/components/Spinner';
import AccountBindings from '@/views/accounts/components/AccountBindings';

import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  components: {
    Spinner,
    AccountBindings,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('ACCOUNTS', ['listItem', 'itemBindings']),
    email() {
      return this.listItem(this.id)?.email;
    },
  },

  async created() {
    this.isLoading = true;
    await this.fetchItem(this.id);
    this.isLoading = false;
  },

  methods: {
    ...mapActions('ACCOUNTS', ['fetchItem', 'changeBindings']),

    handleChangeOrgs: debounce.call(
      this,
      function ({ orgs: targetIds }) {
        this.changeBindings({ id: this.id, targetIds, bindingType: 'orgs' })
          .then(() => {
            this.$notify({
              group: 'note',
              type: 'info',
              title: `Привязки организаций для пользователя ${this.email} успешно изменены.`,
              text: '',
            });
          })
          .catch(() => {
            this.$notify({
              group: 'error',
              type: 'error',
              title: `Произошла ошибка измнения привязок организаций пользователя ${this.email}.`,
              text: '',
            });
          });
      },
      1000,
    ),
  },
};
</script>
